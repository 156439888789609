<template>
    <div class="Head">
        <div class="Back" @click="back">
            <img src="../../assets/SpecialScenic/new/back.png" style="width:100%;height:100%">
        </div>
        <div>{{ DataList.title }}</div>
    </div>
    <div class="ConBox">
        <!-- 图片 -->
        <div class="ImgBox ">
            <!--  -->
            <div class="box">
                <img v-for="(item,index) in ImgList" :key="index" @click="changeCurrent(item)" :src="item.resourceUrl">
            </div>
        </div>
        <!-- 建筑详情 -->
        <div class="Info">
            <div class="Title">建筑详情</div>
            <p>中文名：{{DataList.title}}</p>
            <p>地理位置：{{DataList.provinceName}}{{DataList.cityName}}</p>
            <p>性质：{{DataList.buildingType}}</p>
            <p>建造年代：{{DataList.buildTime}}</p>
            <p>外文名：{{DataList.englishName}}</p>
            <p>占地面积：{{DataList.areaCovered}}</p>
            <p>建造者：{{DataList.builder}}</p>
            <!-- 建筑历史 -->
            <div class="History">
                <div class="Title">建筑历史</div>
                <div style="height: 24vh;">
                    <marquee direction="up" behavior="scroll" scrollamount="1" style="height:100%;line-height: 3.5 vh;text-indent: 2em;">
                        {{ DataList.summary }}
                    </marquee>
                </div>

            </div>
        </div>

        <!-- 视频 -->
        <div class="VideoBox" v-if="videoList.length>0">
            <div class="Title">建筑视频</div>
            <VideoBox :list="videoList" :show="false"></VideoBox>
        </div>
        <div class="DetailsBox">
            <div class="Title">详情</div>
            <div class="content" v-html="DataList.content"></div>
        </div>
    </div>
    <div class="BigImg" v-if="show" @click="closeImg">
        <a class="card" target="_blank" ref="card" @mousemove="move($event)" @mouseleave="leave" @mouseover="over">
            <div class="reflection" ref="refl"></div>
            <img :src="current.resourceUrl" />
        </a>
    </div>
</template>
<script>
import $ from 'jquery'
import VideoBox from '../Public/VideoBox.vue'
export default {
    components: { VideoBox },
    data () {
        return {
            DataList: {},
            ImgList: [],
            videoList: [],
            current: {},
            show: false
        }

    },
    mounted () {
        this.GetDataList()
    },
    methods: {
        back () {
            window.history.go(-1);
        },
        GetDataList () {
            var axios = require("axios");
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/building/detail/' + this.$route.query.ConstructionLandscapeID,
            };
            axios(config).then((res) => {
                this.DataList = res.data.data
                this.ImgList = this.DataList.imgs
                this.videoList = this.DataList.videos
                this.initSwiper()
            }).catch(function (error) {
            });
        },
        initSwiper () {
            var mySwiper = new Swiper('.swiper', {
                autoplay: true,//可选选项，自动滑动
                slidesPerView: 3,
                slidesPerGroup: 1,
                loop: true,
                autoplay: {
                    delay: 1000,
                    pauseOnMouseEnter: true,
                },
                grabCursor: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })
        },
        changeCurrent (item) {
            this.current = item
            this.show = true
        },
        closeImg () {
            this.show = false
        },
        init () {
            $('.ConBox:even').css('flex-direction', 'row-reverse')
        },
        over () {
            const refl = $('.reflection')[0]
            refl.style.opacity = 1;
        },
        leave () {
            const card = $('.card')[0]
            const refl = $('.reflection')[0]
            card.style.transform = `perspective(500px) scale(1)`;
            refl.style.opacity = 0;
        },
        move (event) {
            const card = $('.card')[0]
            const refl = $('.reflection')[0]
            const relX = (event.offsetX + 1) / card.offsetWidth;
            const relY = (event.offsetY + 1) / card.offsetHeight;
            const rotY = `rotateY(${(relX - 0.5) * 60}deg)`;
            const rotX = `rotateX(${(relY - 0.5) * -60}deg)`;
            card.style.transform = `perspective(500px) scale(1.5) ${rotY} ${rotX}`;
            const lightX = this.scale(relX, 0, 1, 150, -50);
            const lightY = this.scale(relY, 0, 1, 30, -100);
            const lightConstrain = Math.min(Math.max(relY, 0.3), 0.7);
            const lightOpacity = this.scale(lightConstrain, 0.3, 1, 1, 0) * 255;
            const lightShade = `rgba(${lightOpacity}, ${lightOpacity}, ${lightOpacity}, 1)`;
            const lightShadeBlack = `rgba(0, 0, 0, 1)`;
            refl.style.backgroundImage = `radial-gradient(circle at ${lightX}% ${lightY}%, ${lightShade} 20%, ${lightShadeBlack})`;
        },
        scale: (val, inMin, inMax, outMin, outMax) =>
            outMin + (val - inMin) * (outMax - outMin) / (inMax - inMin)
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';

.Head {
    width: 100%;
    height: 12vh;
    background: url('../../assets/SpecialScenic/detail/headBg.jpg') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 3vh 10% 0;

    font-size: @First_Title_Size;
    font-weight: 600;
    position: relative;
    text-align: center;
    .Back {
        position: absolute;
        width: 7.5vh;
        height: 7vh;
        left: 10%;
        cursor: pointer;
    }
}
.ConBox {
    background: url(../../assets/SpecialScenic/new/XQbg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 6vh 10% 10vh;
    display: flex;
    flex-wrap: wrap;
    .ImgBox {
        width: 50%;
        // padding-top: 8vh;
        overflow-y: scroll;
        > div {
            transform-style: preserve-3d;
            img {
                display: inline-block;
                width: 32%;
                height: 24vh;
                margin-right: 1%;
                margin-bottom: 1vh;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
    .Info {
        width: 45%;
        margin-left: 5%;
        .Title {
            font-size: @Second_Title_Size;
            font-weight: bold;
        }
        .Box {
            display: flex;
            margin: 2vh 0 3vh;
            div {
                width: 40%;
            }
        }
    }
    .History {
        width: 100%;
        padding: 3vh 0 0;
        .Title {
            font-size: @Second_Title_Size;
            font-weight: bold;
            margin-bottom: 3vh;
        }
    }
    .DetailsBox {
        width: 100%;
        padding: 3vh 0 0;
        .Title {
            font-size: @Second_Title_Size;
            font-weight: bold;
            margin-bottom: 3vh;
        }
        .content {
            /deep/ p {
                text-indent: 2rem;
                font-size: @Content_Text_Size;
                line-height: 3.5vh;
            }
            /deep/ img {
                width: 100%;
            }
        }
    }
    .VideoBox {
        width: 100%;
        padding: 5vh 0 2vh;
        .Title {
            font-size: @Second_Title_Size;
            font-weight: bold;
            margin-bottom: 3vh;
        }
    }

    @remvw: 1920 /100vw !important;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
.BigImg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .card {
        display: block;
        background-color: #ccc;
        max-width: 40%;
        max-height: 60%;
        transition: all 0.1s ease;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        // box-shadow: 0 0 5px rgba(0, 0, 0, 0);
        overflow: hidden;
        cursor: pointer;
        .reflection {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            left: 0;
            top: 0;
            transition: all 0.1s ease;
            opacity: 0;
            mix-blend-mode: soft-light;
        }
        img {
            -o-object-fit: cover;
            object-fit: cover;
            -webkit-filter: grayscale(0.65);
            filter: grayscale(0.65);
            transition: all 0.3s ease;
            object-fit: contain;
        }
    }
    .card:hover {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        z-index: 2;
        // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
        background: transparent;
        img {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            object-fit: contain;
        }
    }
}
</style>